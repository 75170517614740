export interface Marker {
  marker: string;
  description: string;
  min: number;
  max: number;
}

export interface TextFieldMaxLengthEvent {
  name: string;
  maxLength: number;
  rawLength: number;
  estimatedMinLength: number;
  estimatedMaxLength: number;
}

export type NotificationTimesOfDay = 'afternoon' | 'dawn' | 'dusk' | 'evening' | 'midday' | 'morning' | 'night';
export type NotificationParameterValue =
  | boolean
  | Record<NotificationTimesOfDay, number | string>
  | { all_day: number | string };

export type NotificationParameters = Record<string, NotificationParameterValue>;

export enum NotificationSubscriptionCategory {
  NewEmployee = 'new_employee',
}
