/**
 * Global API for notifications.
 *
 */

import API from '@/config/api-routers';
import { Marker, NotificationParameters } from '@/interfaces/notifications.interface';
import { UpdateResponse } from '@/interfaces/shared';
import { NotificationSubscriptionCategory } from '@/interfaces/notifications.interface';
import { makeGetRequest, makeFormRequest } from './requests';

/**
 * Get notifications markers list
 *
 * @access CAN_READ_NOTIFICATION_PARAMETER_INFO
 */
export const getNotificationsMarkers = async (): Promise<Marker[]> => {
  const url = API.notifications.getMarkers;
  const result = await makeGetRequest(url);

  return result.data.result.markers;
};

/**
 * Get notifications remind list
 *
 * @access CAN_READ_NOTIFICATION_PARAMETER_REMIND
 */
export const getNotificationsRemind = async (): Promise<NotificationParameters> => {
  const url = API.notifications.getRemindList;
  const result = await makeGetRequest(url);

  return result.data.result.parameters;
};

/**
 * Get notifications info list
 *
 * @access CAN_READ_NOTIFICATION_PARAMETER_INFO
 */
export const getNotificationsInfo = async (): Promise<NotificationParameters> => {
  const url = API.notifications.getInfoList;
  const result = await makeGetRequest(url);

  return result.data.result.parameters;
};

/**
 * Update notifications remind
 *
 * @access CAN_EDIT_NOTIFICATION_PARAMETER_INFO
 */
export const updateNotificationsRemind = async (data: {}): Promise<UpdateResponse> => {
  const url = API.notifications.updateRemind;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Update notifications info
 *
 * @access CAN_EDIT_NOTIFICATION_PARAMETER_INFO
 */
export const updateNotificationsInfo = async (data: {}): Promise<UpdateResponse> => {
  const url = API.notifications.updateInfo;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Get notifications first shift
 *
 * @access CAN_READ_NOTIFICATION_PARAMETER_FIRST_SHIFT
 */
export const getNotificationsFirstShift = async (): Promise<NotificationParameters> => {
  const url = API.notifications.getFirstShiftList;
  const result = await makeGetRequest(url);

  return result.data.result.parameters;
};

/**
 * Update notifications first shift
 *
 * @access CAN_EDIT_NOTIFICATION_PARAMETER_INFO
 */
export const updateNotificationsFirstShift = async (data: {}): Promise<UpdateResponse> => {
  const url = API.notifications.updateFirstShift;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Get notifications first shift done
 *
 * @access CAN_READ_NOTIFICATION_PARAMETER_FIRST_SHIFT_DONE
 */
export const getNotificationsFirstShiftDone = async (): Promise<NotificationParameters> => {
  const url = API.notifications.getFirstShiftDoneList;
  const result = await makeGetRequest(url);

  return result.data.result.parameters;
};

/**
 * Update notifications first shift done
 *
 * @param data
 *
 * @access CAN_EDIT_NOTIFICATION_PARAMETER_INFO
 */
export const updateNotificationsFirstShiftDone = async (data: {}): Promise<UpdateResponse> => {
  const url = API.notifications.updateFirstShiftDone;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Get notifications cancel shift
 *
 * @access CAN_READ_NOTIFICATION_PARAMETER_CANCEL_SHIFT
 */
export const getNotificationsCancelShift = async (): Promise<NotificationParameters> => {
  const url = API.notifications.getCancelShiftList;
  const result = await makeGetRequest(url);

  return result.data.result.parameters;
};

/**
 * Update notifications cancel shift
 *
 * @param data
 *
 * @access CAN_EDIT_NOTIFICATION_PARAMETER_INFO
 */
export const updateNotificationsCancelShift = async (data: {}): Promise<UpdateResponse> => {
  const url = API.notifications.updateCancelShift;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Get notifications reactivation
 *
 * @access CAN_READ_NOTIFICATION_PARAMETER_EMPLOYEE_REACTIVATION
 */
export const getNotificationsReactivation = async (): Promise<NotificationParameters> => {
  const url = API.notifications.getReactivationList;
  const result = await makeGetRequest(url);

  return result.data.result.parameters;
};

/**
 * Update notifications reactivation
 *
 * @param data
 *
 * @access CAN_EDIT_NOTIFICATION_PARAMETER_INFO
 */
export const updateNotificationsReactivation = async (data: {}): Promise<UpdateResponse> => {
  const url = API.notifications.updateReactivation;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Get notifications check in check out
 *
 * @access CAN_READ_NOTIFICATION_PARAMETER_EMPLOYEE_CHECKIN
 */
export const getNotificationsCheckin = async (): Promise<NotificationParameters> => {
  const url = API.notifications.getCheckinList;
  const result = await makeGetRequest(url);

  return result.data.result.parameters;
};

/**
 * Update notifications check in check out
 *
 * @param data
 *
 * @access CAN_EDIT_NOTIFICATION_PARAMETER_INFO
 */
export const updateNotificationsCheckin = async (data: {}): Promise<UpdateResponse> => {
  const url = API.notifications.updateCheckin;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Get notifications new market
 */
export const getNotificationsNewMarket = async (): Promise<NotificationParameters> => {
  const url = API.notifications.getNewMarketList;
  const result = await makeGetRequest(url);

  return result.data.result.parameters;
};

/**
 * Update notifications new market
 */
export const updateNotificationsNewMarket = async (data: {}): Promise<UpdateResponse> => {
  const url = API.notifications.updateNewMarket;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Get notifications auto assigned
 */
export const getNotificationsAutoAssigned = async (): Promise<NotificationParameters> => {
  const url = API.notifications.getAutoAssignedList;
  const result = await makeGetRequest(url);

  return result.data.result.parameters;
};

/**
 * Update notifications auto assigned
 */
export const updateNotificationsAutoAssigned = async (data: {}): Promise<UpdateResponse> => {
  const url = API.notifications.updateAutoAssigned;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

export const subscribeNotifications = async (data: {
  email: string;
  notificationCategory: NotificationSubscriptionCategory;
}): Promise<void> => {
  const url = API.notificationSubscriptions.subscribe;
  await makeFormRequest(url, data);
};

export const unsubscribeNotifications = async (payload: { notificationCategory: NotificationSubscriptionCategory }): Promise<void> => {
  const url = API.notificationSubscriptions.unsubscribe;
  await makeFormRequest(url, payload);
};
